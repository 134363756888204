<template>
	<div>
		<div style="background: #fff; margin-bottom: 24px; padding-top: 12px;">
			<Form :label-width="80" inline>
				<FormItem label="用户名">
					<Input placeholder="输入用户名搜索" v-model="search.name"></Input>
				</FormItem>
				<FormItem label="手机号">
					<Input placeholder="输入手机号搜索" v-model="search.phone"></Input>
				</FormItem>
				<FormItem :label-width="0">
					<Button type="primary" @click="changePage(1)">搜索</Button>
				</FormItem>
			</Form>
		</div>
		<div class="table-list">
			<Table :columns="columns" :data="data">
				<template slot-scope="{row}" slot="amount">
					<div v-if="row.amount">
						<span>{{row.amount+'/台'}}</span>
						<a href="javascript:;" @click="openModel(row.id)">&nbsp;修改套餐</a>
					</div>
					<Button v-else type="warning" @click="open(row.id)">立即开通</Button>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" :total="search.total" :page-size="search.pageSize" :page-size-opts="[15,30,50]"
				@on-change="changePage" @on-page-size-change="changePageSize" show-total show-sizer />
		</div>
		<Modal title="设备流量操作" v-model="model">
			<Form ref="formInline" :model="formData">
				<FormItem label="增加数量">
					<RadioGroup v-model="formData.type">
						<Radio :label="1">增加</Radio>
						<Radio :label="0">减少</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem :label="formData.type?'增加设备数量':'减少设备数量'">
					<InputNumber style="width: 160px;" :active-change="false" :max="99999" :min="1"
						v-model="formData.amount" :formatter="value => `${value} /台`"
						:parser="value => value.replace('/台', '')"></InputNumber>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="model = false">取消</Button>
				<Button type="primary" @click="addAmount">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: "manage",
		data() {
			return {
				model: false,
				formData: {
					id: null,
					type: 1,
					amount: 1,
				},
				columns: [{
						title: '序号',
						type: 'index',
						width: 60,
						align: 'center'
					},
					{
						title: '用户名',
						key: 'name'
					},
					{
						title: '手机号',
						key: 'phone'
					},
					{
						title: '绑定数量',
						key: 'device_number'
					},
					{
						title: '设备流量',
						slot: 'amount'
					},
					{
						title: '状态',
						key: 'status',
						render: (h, params) => {
							return h('tag', {
								props: {
									color: params.row.status ? 'success' : 'default'
								}
							}, params.row.status ? '正常' : '禁用')
						}
					},
					{
						title: '创建时间',
						key: 'add_time'
					}
				],
				data: [],
				search: {
					name: '',
					phone: '',
					status: 1,
					page: 1,
					pageSize: 15,
					total: 0,
				}
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				var that = this;
				this.loading = true;
				this.requestApi('/adm/get_admin_list', {
					search: this.search
				}).then(function(res) {
					if (res.status) {
						that.search.total = res.data.total
						that.data = res.data.data
					}
				})
			},
			open(id) {
				var that = this
				this.$Modal.confirm({
					title: '提示',
					content: '确定要开通吗？确认后无法更改。',
					onOk() {
						that.requestApi('/adm/open_admin_package', {
							data: id
						}).then((res) => {
							if (res.status) {
								that.alertSucc(res.msg)
								that.init()
							} else {
								that.alertErr(res.msg)
							}
						})
					}
				})
			},
			openModel(id) {
				this.formData.id = id;
				this.formData.amount = 1;
				this.model = true;
			},
			addAmount() {
				var that = this
				this.requestApi('/adm/add_admin_package', {
					data: this.formData
				}).then((res) => {
					if (res.status) {
						that.alertSucc(res.msg)
						that.model = false
						this.init()
					} else {
						that.alertErr(res.msg)
					}
				})
			},
			changePage(page) {
				this.search.page = page;
				this.init();
			},
			changePageSize(size) {
				this.search.page = 1;
				this.search.pageSize = size;
				this.init();
			},
		}
	}
</script>

<style scoped>

</style>